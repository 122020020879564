import Card from '@mui/material/Card'
import { CoreDocument } from '@rallycry/api-suite-typescript/dist/models/CoreDocument'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import Box from '@mui/material/Box'
import { GenericCoreDocRow } from './GenericCoreDocRow'
import { MatchCoreDocRow } from './MatchCoreDocRow'
import { RcSuspense } from '@/components/atoms/RcSuspense'

export type LookupsType = Partial<
  Record<
    EntityType,
    {
      name: React.ReactNode
      description: React.ReactNode
      tagline: React.ReactNode
      path: string
      organizerName: React.ReactNode
    }
  >
>
export interface CoreDocRowProps {
  coreDoc: CoreDocument
}

export const CoreDocRow = ({ coreDoc }: CoreDocRowProps) => {
  return (
    <RcSuspense skipLoader>
      {coreDoc.reference?.type === EntityType.BRACKETMATCH ? (
        <MatchCoreDocRow coreDoc={coreDoc} />
      ) : (
        <Card elevation={3}>
          <Box
            sx={{
              px: { md: 6, lg: 6 },
              py: { md: 6, lg: 3 }
            }}
          >
            <GenericCoreDocRow coreDoc={coreDoc} />
          </Box>
        </Card>
      )}
    </RcSuspense>
  )
}
