import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

export const PreventParentClick = ({ children }: PropsWithChildren) => {
  return (
    <Box
      onClick={ev => {
        ev.stopPropagation()
      }}
    >
      {children}
    </Box>
  )
}
