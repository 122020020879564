import Stack from '@mui/material/Stack'
import isEmpty from 'lodash-es/isEmpty'
import { CoreDocRow } from './CoreDocRow'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useCoreDocument } from '@/entity/site/useCoreDocument'

export const MyFeed = () => {
  const { organization } = useOrganization()
  const orgId = organization?.id
  const { cores } = useCoreDocument({
    request: { organization: orgId }
  })

  if (isEmpty(cores)) return null

  return (
    <PageItem contained={false}>
      <PageItemContent pb={0}>
        <SectionHeader title={<RcTrans i18nKey='home:info.upcoming' />} />
      </PageItemContent>

      <PageItemContent>
        <Stack spacing={2}>
          {cores
            ?.sort((a, b) => (b?.reference?.id! > a?.reference?.id! ? 1 : -1))
            .map(it => <CoreDocRow key={it.id} coreDoc={it} />)}
        </Stack>
      </PageItemContent>
    </PageItem>
  )
}
